import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import Hero from '../components/hero/hero';
import Reasons from '../components/reasons/reasons';
import Helps from '../components/helps/helps';
import KnowBetter from '../components/know-better/knowBetter';
import TariffList from '../components/tariff-list/tariffList';
import FAQ from '../components/faq/faq';
import Slider from '../components/slider/slider';
import Recommendations from '../components/recommendations/recommendations';
import Seo from '../components/seo';
import Section from '../components/section/section';
import AgreeWithCookies from '../components/agree-with-cookies/agreeWithCookies';

import { yaEvent } from '../helper/metrika';
import { setCookie } from '../helper/cookies';
import { getUtmQueriesFromSearch } from '../helper/utmQueries';

const MENU_LEFT = [
  {
    title: 'Главная',
    value: '/',
    mobile: true
  },
  {
    title: 'Возможности',
    value: '/#reasons'
  }, {
    title: 'Плюсы',
    value: '/#helps'
  }, {
    title: 'Заказать демо',
    value: '/#know-better'
  }, {
    title: 'Отзывы',
    value: '/#reviews'
  }, {
    title: 'Тарифы',
    value: '/#tariffs',
    onClick: () => yaEvent('pressed_button_pricing')
  }, {
    title: 'Блог',
    value: 'https://blog.sberpodbor.ru/',
    newWindow: true
  }
];

const IndexPage = ({ location }) => {
  const { hostname } = location;

  if (hostname) {
    const { protocol, search } = location;

    const utmQueries = getUtmQueriesFromSearch(search);

    if (Object.keys(utmQueries).length) {
      setCookie('ats:utm', utmQueries, {
        expires: 365 * 10,
        domain: hostname,
        secure: protocol.indexOf('https') === 0,
        sameSite: 'strict'
      });
    }
  }

  const [isScroll, setIsScroll] = useState(false);
  const knowBetterFormRef = useRef();

  function onScroll () {
    setIsScroll(true);
    if (!window.pageYOffset) {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Layout isScroll={isScroll} menuItems={MENU_LEFT}>
      <Seo title="" lang={'ru'}/>

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'SoftwareApplication',
            'name': 'СберПодбор',
            'applicationCategory': 'BusinessApplication',
            'operatingSystem': 'Web',
            'url': 'https://sberpodbor.ru',
            'screenshot': 'https://sberpodbor.ru/images/hero@2x.png',
            'inLanguage': 'Русский',
            'description': 'СберПодбор — система для ведения базы кандидатов, аналитика, добавление в единую базу кандидатов из разных площадок поиска сотрудников, единая CRM - работайте сразу и без оплаты',
            'aggregateRating': {
              '@type': 'AggregateRating',
              'ratingValue': '4.9',
              'ratingCount': '100'
            },
            'author': {
              '@type': 'Organization',
              'url': 'https://sberpodbor.ru',
              'name': 'СберПодбор',
              'email': 'sales@sberpodbor.ru',
              'telephone': '+7(495)139-35-59',
              'address': {
                '@type': 'PostalAddress',
                'addressLocality': 'Россия'
              }
            }
          })}
        </script>
        <script type="text/javascript">
          {`
            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push(\{id: "3562751", type: "pageView", start: (new Date()).getTime()});
            (function (d, w, id) {
              if (d.getElementById(id)) return;
              var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
              ts.src = "https://top-fwz1.mail.ru/js/code.js";
              var f = function () \{var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
              if (w.opera == "[object Opera]") \{ d.addEventListener("DOMContentLoaded", f, false); } else \{ f(); }
            })(document, window, "tmr-code");
          `}
        </script>
        <noscript>
          {'<div><img src="https://top-fwz1.mail.ru/counter?id=3562751;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>'}
        </noscript>
      </Helmet>

      <Section id={'hero'}>
        <Hero />
      </Section>

      <Section id={'reasons'}>
        <Reasons />
      </Section>

      <Section id={'helps'}>
        <Helps />
      </Section>

      <Section id={'tariffs'}>
        <TariffList page="index" />
      </Section>

      <Section>
        <FAQ />
      </Section>

      <Section id={'reviews'}>
        <Slider />
      </Section>

      <Section>
        <Recommendations />
      </Section>

      <Section
        id={'know-better'}
        ref={knowBetterFormRef}
        viewport
        zero
      >
        <KnowBetter />
      </Section>
      <AgreeWithCookies />
    </Layout>
  );
};

export default IndexPage;
