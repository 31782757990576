import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import SliderArrow from '../slider-arrow/sliderArrow';

import * as s from './slider.module.scss';

const Slider = () => {
  const settings = {
    speed: 500,
    adaptiveHeight: true,
    prevArrow: <SliderArrow type={'prev'} />,
    nextArrow: <SliderArrow type={'next'} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true
        }
      }
    ]
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  return (
    <div className={cn(s.slider, 'slider', 'reviews')}>
      <div className="container">
        <h2 className={cn(s.sliderTitle, 'title-2')}>СберПодбор уже выбрали более 18&nbsp;500 компаний</h2>
        {isLoaded && <SlickSlider {...settings} className={s.slickSlider}>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/6.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Сараева Александра</span>
                <span className={s.slidePosition}>Директор по персоналу</span>
                <span className={s.slideCompany}>ЗАО “Завод ЭМА”</span>
              </div>
              <div className={s.slideContent}>
                Я в HR более 10 лет и использовала несколько CRM для автоматизации подбора, но СберПодбор выбрала из-за эргономичности платформы, интуитивно-понятного интерфейса. С одной стороны, в СберПодборе есть все необходимое для работы с наймом; с другой – нет нагромождения функционала, понятно где какая фукнция находится и ей воспользоваться.<br/>
                Пожалуй, самыми востребованными функциями стали:<br/>
                <ul>
                  <li>интеграция с календарем Google и Outlook;</li>
                  <li>интеграция с Telergam, whats app и почта;</li>
                  <li>подключение по API с HH и Работа.ру;</li>
                  <li>возможность делать рассылки.</li>
                </ul>
                Рекрутеры, безусловно, используют несколько источников взаимодействия с кандидатами – очень удобно, что все коммуникации можно вести в одном сервисе.
                Сценарии звонков и писем также помогают рекрутеру общаться с разными категориями кандидатов в определенном tone of voice.<br/>
                Я всегда всем рекомендую СберПодбор, уже 3 компании перевела на эту CRM-систему, потому что это удобный отечественный продукт.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/1.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Шумилова Евгения</span>
                <span className={s.slidePosition}>Менеджер по подбору персонала</span>
                <span className={s.slideCompany}>ООО “Глобас Транс”</span>
              </div>
              <div className={s.slideContent}>
                Я выбрала СберПодбор, так как мне нужна была CRM для своей работы.<br/>
                Предпочтение этой программе отдала по причине интуитивно понятного интерфейса и конкурентной цены.
                Система позволяет автоматизировать мою работу: видеть все движения по кандидатам (собеседование, согласование и т.д.), есть возможность отправки сообщений напрямую в ватсап кандидату, есть возможность прикреплять документы в карточке кандидата.
                СберПодбор станет вашей удобной “электронной записной книжкой” и хорошим помощником в работе. Рекомендую! Для массового подбора подходит идеально.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/2.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Зеленко Константин</span>
                <span className={s.slidePosition}>Исполнительный директор</span>
                <span className={s.slideCompany}>ИП Таубина Софья Антоновна</span>
              </div>
              <div className={s.slideContent}>
                За 5 лет в HR и трудоустройстве более 200 человек втч в Microsoft, Amazon, Github, Evernote я скажу что очень жалею что не наткнулся на СберПодбор в начале своей карьеры. Настолько удобного инструмента для самого ключевого процесса HRBP – работа с нанимающим менеджером с воронкой подбора изобрести сложно. При это у меня был опыт в международных стартапах где используют зарубежные аналоги и объективно это самый удобный гибкий инструмент для построения прозрачного процесса найма в вашу команду. Очень рекомендую покупать стандарт и внедрять авторазбор из hh тк это ускоряет работу в 5 раз.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/3.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Мельник Светлана</span>
                <span className={s.slidePosition}>Менеджер по подбору персонала</span>
                <span className={s.slideCompany}>ООО Слон-Электроникс</span>
              </div>
              <div className={s.slideContent}>
                Изначально выбрали именно СберПодбор благодаря возможности использования бесплатной части функционала, т.к. ранее не использовали подобные сервисы для подбора и не было понимания какие конкретно функции нужны нашему бизнесу.
                В рамках бесплатного тарифа чаще всего используем конечно Базу кандидатов по вакансиям, СуперКнопку, статусы кандидатов. Отличный сервис для оптимизации подбора на начальном этапе развития!
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/4.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Улыбина Яна</span>
                <span className={s.slidePosition}>Руководитель кадрового агентства</span>
                <span className={s.slideCompany}>HR-Control</span>
              </div>
              <div className={s.slideContent}>
                Мы – агентство по подбору персонала “HR-Control” предпочитаем использовать «СберПодбор», так как этот сервис делает подбор персонала более продуктивным.<br/>
                Особенно ценным является то, что «СберПодбор» помогает нам сэкономить время. Благодаря этому, процесс подбора персонала стал значительно быстрее. Клиент оставляет заявку на подбор прямо в сервисе «СберПодбор», согласование кандидатов происходит тут же - онлайн, а после, мы очень быстро получаем обратную связь по кандидатам и выбираем наилучшего.
                Советую использовать «СберПодбор» для улучшения процесса подбора персонала. Этот сервис поможет сократить время, предоставляет доступ к большой базе кандидатов и помогает выбирать лучших.<br/>
                Попробуйте «СберПодбор» – Вы увидите разницу в эффективности найма.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/5.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Канарейкина Юлия</span>
                <span className={s.slidePosition}>Менеджер по персоналу</span>
                <span className={s.slideCompany}>ООО “Галфвинд”</span>
              </div>
              <div className={s.slideContent}>
                Мы рассматривали многие CRM-системы для рекрутинга, т.к. стало крайне неудобно вести базу кандидатов в Excel и к тому же “вытаскивать” оттуда аналитику. В итоге мы остановились на СберПодборе по многим факторам:<br/>
                <ul>
                  <li>Простота использования: интуитивно понятный интерфейс, легкая настройка доступа, удобная визуализация;</li>
                  <li>Основные варианты отчетов. Да, каких-то отчетов может не хватать, но система позволяет скачать данные в excel и уже на основании полученных данных строить свою систему показателей;</li>
                  <li>Скромная цена не отражается на качестве продукта.</li>
                </ul>
                Самое полезное для нас в СберПодборе – простота в работе рекрутера:<br/>
                <ul>
                  <li>легкое добавление резюме кандидатов;</li>
                  <li>удобная работа со статусами;</li>
                  <li>общение с кандидатами в системе одного окна.</li>
                </ul>
                СберПодбор экономит наше время на подбор персонала.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/7.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Бобыкина Анастасия</span>
                <span className={s.slidePosition}>ИП Бобыкина Анастасия Викторовна</span>
              </div>
              <div className={s.slideContent}>
                Выбрала СберПодбор, т.к. на мой взгляд система была оптимальной по стоимости и наличию функционала для маленькой команды HR с наличием постоянного рекрутинга. Функций немного, но есть основное: загрузка резюме, хранение и соблюдение истории общения с кандидатами, Нашей команде на текущем этапе этого достаточно.<br/>
                Полезно: просто и без лишней нагрузки интерфейса можно хранить резюме кандидатов под разными вакансиями, отслеживая статусы, отслеживая статистику работы рекрутеров. Хорошо, что можно загружать резюме группами. Удобно, что можно писать кандидату в мессенджер, используя шаблоны сообщений.
                Быстро реагирует техподдержка на вопросы. Сейчас ждем реализацию нашего запроса на сращивание дублей при групповой добавлении и сможем работать ещё продуктивнее!<br/>
                Рекомендую СберПодбор, как первую площадку для закрытия базового функционала рекрутера, для команд с небольшим количеством вакансий в ежемесячном подборе.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/8.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Башкирова Светлана</span>
                <span className={s.slidePosition}>Самозанятый рекрутер</span>
              </div>
              <div className={s.slideContent}>
                В 2022 году было много вакансий в работе, искала ATS для упрощения работы. Нашла предложение от СберПодбор и сразу приняла решение попробовать, т.к. уже тогда было много нужных мне функций и при этом – бесплатно.<br/>
                Даже после введения платного тарифа не было мыслей сменить систему, т.к. устраивает соотношение цены и возможностей системы.<br/>
                Самые полезные функции:<br/>
                <ul>
                  <li>Удобная кнопка для посадки резюме с работных сайтов, в т.ч. с авито.</li>
                  <li>Возможность писать в мессенджеры сразу из карточки резюме.</li>
                  <li>Шаблоны сообщений и писем для рассылок.</li>
                  <li>Возможность приглашать сторонних пользователей (заказчика) для согласования резюме кандидатов в конкретную вакансию.</li>
                </ul>
                Рекомендую систему СберПодбор, т.к. она значительно упрощает и ускоряет работу рекрутеров: позволяет хранить и фильтровать накопленные резюме, коммуницировать с кандидатами напрямую, делать массовые рассылки кандидатам и многое другое. При этом, значительно отличается от других систем для рекрутинга выгодной ценой.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/9.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Быкова Светлана</span>
                <span className={s.slidePosition}>Директор по персоналу</span>
                <span className={s.slideCompany}>ООО “Волганефтепродукт”</span>
              </div>
              <div className={s.slideContent}>
                Сервис СберПодбор я выбрала по рекомендации коллег. По сравнению с аналогичными продуктами, СберПодбор, на мой взгляд, имеет несколько преимуществ. Во-первых, очень прост в использовании: любой рекрутер, даже начинающий, может легко разобраться. Во-вторых, сервис можно использовать в web-формате, а не только со стационарного ПК, что упрощает работу. В-третьих, мне как руководителю стало гораздо проще контролировать состояние дел по рекрутингу: в любой момент можно получить нужную информацию о статусе подбора, не отвлекая сотрудников от работы. Очень полезная функция – возможность найти резюме кандидата во внутренней базе по ключевым словам. При всех преимуществах сервис имеет довольно адекватную стоимость (по сравнении с аналогами), а также бесплатную версию. Однозначно рекомендую сервис рекрутерам и нанимающим менеджерам для упрощения рутинных операций по рекрутингу.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/10.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Кокарева Карине</span>
                <span className={s.slidePosition}>Руководитель агентства</span>
                <span className={s.slideCompany}>ИП Кокарева Карине Грачиковна</span>
              </div>
              <div className={s.slideContent}>
                Я долго искала удобную CRM-систему для нашей команды и испробовала многие на рынке. Не каждая система подходила под нужный функционал, либо была абсолютно не юзерфрендли. Знакомый мне рассказал о СберПодборе, и я решила попробовать. Это лучшее, что я знаю. Приятно, что система такая доступная для многих.<br/>
                Мне очень удобно, что приложение доступно онлайн и его можно открыть легко в браузере. Одним из главных преимуществ, я бы отметила то, что добавить кандидатов можно одной кнопкой вручную из файла или при помощи расширения, а оно работает со всеми популярными сайтами для поиска работы и сотрудников. И очень гибкая настройка воронки найма, которая позволяет отслеживать все необходимые этапы. А также можно провести хорошую аналитику по воронке подбора и по работе рекрутеров компании.<br/>
                Я бы посоветовала не тратить время на поиск удобной CRM-системы для работы. Ведь лучший уже есть и, воспользовавшись им однажды, другого не захочется. Этот сервис помогает компаниям выстроить работу по найму и наладить обработку большого количества резюме. И ещё точно уверена, что качество подбора персонала повысится.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/11.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Рахимова Азалия</span>
                <span className={s.slidePosition}>Специалист по подбору персонала</span>
                <span className={s.slideCompany}>ООО “КамаСтройИнвест”</span>
              </div>
              <div className={s.slideContent}>
                Подбор персонала становится удобнее и быстрее. Добавить кандидата можно одним кликом. Совместная работа с заказчиком по кандидату и возможность общаться с соискателем в мессенджере делают процесс прозрачнее и проще.<br/>
                Можно отслеживать продуктивность рекрутеров. Можно видеть всю историю общения с кандидатами, даже если рекрутер больше не работает в компании. Преимуществом является то, что есть отчет по воронке и отказам.<br/>
                Самыми полезными функциями СберПодбор – это возможность найти узкие места в вакансии. Понять основные причины отказов, детализировать отказы на каждом этапе. Так же очень полезна функция отслеживания найма, понимание конверсии перехода кандидатов с этапа на этап.<br/>
                Я советую всем СберПодбор так как все нужные нам функции есть на данной платформе: база в одном месте с разных сервисов, чатов. Можно написать соискателю в мессенджеры, можно по email. Так же есть отличная тех.поддержка, которая всегда поможет и объяснит, как работает платформа.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/12.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Трачук Юлия</span>
                <span className={s.slidePosition}>Бизнес-ассистент</span>
                <span className={s.slideCompany}>ООО “ИнКомПро”</span>
              </div>
              <div className={s.slideContent}>
                На протяжении моего уже 10-летнего опыта работы с персоналом и поиском я испытывала потребность, чтобы была какая-то система, где было бы легко хранить, передавать, комментировать резюме кандидатов. Раньше все ограничивалось почтой, Excel, Access в конце концов. И я была безумно рада, когда в августе 2021 года появился новый сервис СберПодбор и попробовав его, мы уже не хотим от него отказываться. К нему подключались другие наши рекрутеры, руководители, менеджеры и все были очень довольны, что могут в одном месте рассматривать кандидатов, оценивать их, согласовывать. О самом удобстве для рекрутера, который подбирает кандидатов и общается с ними надо сказать отдельно. Это прекрасно, что ты можешь писать из прямо из сервиса кандидатам в мессенджеры, потому что сейчас в наш век это стало очень необходимо. Кандидаты сейчас считают удобным, что их не донимают звонками, а пишут, чтобы они могли ответить тут же. К тому же со стороны рекрутера – можно всегда понять, прочитали ли твое сообщение, чтобы принимать решение о дальнейших действиях, а не теряться в догадках.<br/>
                Удобно, что загружать резюме в систему так просто – можно загрузить файл резюме с работного сайта и это занимает секунды. А повторно загрузить лишнее повторное резюме вы не сможете – система тут же предупредит, что такое резюме уже есть.
                Большое спасибо разработчикам, что сделали такой удобный инструмент. Надеемся, что вскоре появится еще больше функций.
              </div>
            </div>
          </div>
          <div className={s.slickSlide}>
            <div className={s.slide}>
              <div className={s.slideHeader}>
                <img
                  src="/images/reviews/13.png"
                  className={s.slideImg}
                />
                <span className={s.slideUserName}>Чистова Аглая</span>
                <span className={s.slidePosition}>Директор по персоналу</span>
                <span className={s.slideCompany}>ООО “Новое место”</span>
              </div>
              <div className={s.slideContent}>
                Я выбрала программу СберПодбор, потому что мне нужна была CRM для подбора и она оказалась самой подходящей по цене и наполнению. Я начала с ней работать, как только CRM появилась на рынке. Мне нравится, что в СП можно вести кандидатов по воронке, при этом – для разных должностей создавать свою воронку. Так можно работать как с массовым подбором, так и с точечными сложными вакансиями.<br/>
                Удобно, что можно отправлять сообщения из карточки кандидата, делать для этого шаблоны – хорошо подходит для рассылки вакансии, приглашения на собеседование и т.п., не нужно тратить время на перепечатывание текста или хранить шаблоны в разных файлах. Можно скачивать резюме, писать комментарии, добавлять ссылки и файлы на пройденные тестирования – это позволяет быстрее обсуждать кандидата с заказчиком; я подключила к программе всех руководителей и теперь не нужно искать в переписке обсуждения той или иной кандидатуры.<br/>
                Удобно, что можно не только сохранять резюме кнопкой в браузере, но и закачивать файлы, которые приходят из других источников. Они не всегда корректно распознаются, но я верю, что СП будет совершенствоваться 
                У другой похожей CRM (не буду писать название, но функционал почти одинаковый) нельзя сохранять многие резюме напрямую с сайта и проблемы с закачиванием сторонних файлов – это большая проблема при работе с разными источниками подбора (а использовать только один-два популярных сайта не получится для корректной работы HR).<br/>
                В общем и целом – мне очень удобна программа СберПодбор, я её использую на своём основном месте работы, а также установила пяти компаниям, где работала проектно. СП помогает автоматизировать подбор, делает прозрачными статистики рекрутеров и выполняет главную функцию – систематизация всех кандидатов в одном месте, без потерь. У неё современный дизайн, она простая и действительно интуитивно понятная – войти и сразу начать работать может любой рекрутер или заказчик.
                Желаю СберПодбору и дальше развиваться, дополнять функционал программы и максимально автоматизировать HR процессы. Спасибо!<br/>
                Когда много вакансий в работе – я вижу наглядно, где находится каждый кандидат, и, что мне очень понравилось, сколько времени проводит кандидат на этапе – это очень удобное нововведение, помогающее развивать HR бренд компании и давать обратную связь вовремя.
              </div>
            </div>
          </div>
        </SlickSlider>}
      </div>
    </div>
  );
};

export default Slider;
