import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import Button from '../button/button';

import * as s from './agreeWithCookies.module.scss';

const AgreeWithCookies = () => {
  const [isClosed, setIsClosed] = useState(true);

  const agree = () => {
    setIsClosed(true);
    localStorage.setItem(
      'ats:cookies',
      JSON.stringify({
        value: true
      })
    );
  };

  useEffect(() => {
    const cookies = localStorage.getItem('ats:cookies');
    const isAgree = (cookies && JSON.parse(cookies).value) || false;
    setIsClosed(isAgree);
  }, [isClosed]);

  return (
    <div className={cn(s.cookies, { [s.cookiesAnimated]: isClosed })}>
      <p className={s.cookiesText}>
        Мы используем cookies. Продолжая работать с сайтом, вы соглашаетесь на обработку персональных данных в соответствии с&nbsp;
        <a
          href={'/doc/Политика_обработки_персональных_данных.pdf'}
          className="text-blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          политикой конфиденциальности.
        </a>
      </p>
      <Button
        className={s.cookiesButton}
        onClick={() => agree()}
        text={'Принять и закрыть'}
        small
      />
    </div>
  );
};

export default AgreeWithCookies;
