import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import SlickSlider from 'react-slick';

import SliderArrow from '../slider-arrow/sliderArrow';

import * as s from './recommendations.module.scss';
import './recommendations.scss';

const Recommendations = () => {

  // 1 число - точно-кол во миллисекунд в соответствии с css анимацией
  // 2 число - дополнительная задержка (может быть разной в зависимости от кол-во текста в слайде)
  const slidesDelay = {
    0: 8000 + 3000,
    1: 5000 + 2000,
    2: 3500 + 17000
  };
  const numOfSlides = Object.keys(slidesDelay).length;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [speed, setSpeed] = useState(slidesDelay[0]);

  const [swipe, setSwipe] = useState(true);

  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: speed,
    prevArrow: <SliderArrow type={'prev'} />,
    nextArrow: <SliderArrow type={'next'} />,
    beforeChange: current => {
      const index = numOfSlides - 1 === current ? 0 : current + 1;
      setSpeed(slidesDelay[index]);
    },
    afterChange: current => {

      setCurrentSlide(current);

      setSwipe(false);

      setTimeout(() => {
        setSwipe(true);
      }, 100);
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  return (
    <div className={cn(s.recommendations, 'recommendations', { 'swipe': swipe })}>
      <div className="container">
        <h2 className={cn('title-2')}>Нас рекомендуют</h2>
        <div className={s.recommendationsContent}>
          <div className={s.recommendationsContentText}>
            <p className={s.recommendationsContentTitle}>Наши клиенты делятся результатами работы со СберПодбором:</p>
            <ul>
              <li className={s.recommendationsListItem}>согласование кандидата занимает от пяти минут до трех дней, а не от трех дней до двух месяцев, как раньше (KAZPROM AVTOMATIKA);</li>
              <li className={s.recommendationsListItem}>отчетность можно выгрузить за пару кликов, рекрутеры видят свой прогресс — это отлично сказывается на их мотивации (RedCloudLab).</li>
            </ul>
            <p>И советуют сервис коллегам в профессиональных сообществах.</p>
            <div className={s.recommendationsEmail}>
              <h5 className={s.recommendationsEmailTitle}>Хотите оставить свой отзыв?</h5>
              <div>
                Напишите нам на <a href="mailto:sales@sberpodbor.ru" className={s.recommendationsEmailLink} target="_blank" rel="noreferrer">sales@sberpodbor.ru</a>
              </div>
            </div>
            <div className={s.phoneSliderInfo}>
              <div className={cn(s.phoneSliderProgress)}>
                <div className={cn(s.phoneSliderProgressValue, 'progress-value')} style={{ animationDuration: `${speed}ms` }}></div>
              </div>
              <div>
                <span className={s.phoneSliderCurrent}>{currentSlide + 1}</span>
                <span className={s.phoneSliderCount}>/{numOfSlides}</span>
              </div>
            </div>
          </div>
          <div className={cn(s.phone)}>
            <img src={'/images/phone/phone.png'} className={s.recommendationsImage} alt={''} />
            <div className={cn(s.phoneSliderWrapper)}>
              {isLoaded && <SlickSlider {...settings}>
                <div className={cn({ [s.phoneSlideActive]: currentSlide === 0 })}>
                  <img src={'/images/phone/slide-1-1.svg'} className={cn(s.phoneMessage, s.phoneMessage1, s.phoneMessageFrom)} alt={''} />
                  <img src={'/images/phone/slide-1-2.svg'} className={cn(s.phoneMessage, s.phoneMessage2)} alt={''} />
                  <img src={'/images/phone/slide-1-3.svg'} className={cn(s.phoneMessage, s.phoneMessage3)} alt={''} />
                  <img src={'/images/phone/slide-1-4.svg'} className={cn(s.phoneMessage, s.phoneMessage4)} alt={''} />
                  <img src={'/images/phone/slide-1-5.svg'} className={cn(s.phoneMessage, s.phoneMessage5, s.phoneMessageFrom)} alt={''} />

                  <div className={cn(s.phoneLoaderFrom1, s.phoneLoaderFrom, s.phoneLoader)}>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot1)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot2)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot3)}></div>
                  </div>

                  <div className={cn(s.phoneLoaderTo1, s.phoneLoaderTo, s.phoneLoader)}>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot1)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot2)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot3)}></div>
                  </div>
                </div>
                <div className={cn({ [s.phoneSlideActive]: currentSlide === 1 })}>
                  <img src={'/images/phone/slide-2-1.svg'} className={cn(s.phoneMessage, s.phoneMessage1, s.phoneMessageFrom)} alt={''} />
                  <img src={'/images/phone/slide-2-2.svg'} className={cn(s.phoneMessage, s.phoneMessage2)} alt={''} />
                  <img src={'/images/phone/slide-2-3.svg'} className={cn(s.phoneMessage, s.phoneMessage3, s.phoneMessageFrom)} alt={''} />

                  <div className={cn(s.phoneLoaderFrom2, s.phoneLoaderFrom, s.phoneLoader)}>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot1)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot2)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot3)}></div>
                  </div>

                  <div className={cn(s.phoneLoaderTo2, s.phoneLoaderTo, s.phoneLoader)}>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot1)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot2)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot3)}></div>
                  </div>
                </div>
                <div className={cn({ [s.phoneSlideActive]: currentSlide === 2 })}>
                  <img src={'/images/phone/slide-3-1.svg'} className={cn(s.phoneMessage, s.phoneMessage1, s.phoneMessageFrom)} alt={''} />
                  <img src={'/images/phone/slide-3-2.svg'} className={cn(s.phoneMessage, s.phoneMessage2)} alt={''} />

                  <div className={cn(s.phoneLoaderFrom3, s.phoneLoaderFrom, s.phoneLoader)}>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot1)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot2)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot3)}></div>
                  </div>

                  <div className={cn(s.phoneLoaderTo3, s.phoneLoaderTo, s.phoneLoader)}>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot1)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot2)}></div>
                    <div className={cn(s.phoneLoaderDot, s.phoneLoaderDot3)}></div>
                  </div>
                </div>
              </SlickSlider>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
