import React from 'react';

import * as s from './selectedText.module.scss';

const SelectedText = ({ children  }) => {
  return (
    <span className={s.text}>{children}</span>
  );
};

export default SelectedText;
