// extracted by mini-css-extract-plugin
export var recommendations = "recommendations-module--recommendations--296Sm";
export var recommendationsContent = "recommendations-module--recommendationsContent--U300x";
export var recommendationsContentText = "recommendations-module--recommendationsContentText--JIrq6";
export var recommendationsContentTitle = "recommendations-module--recommendationsContentTitle--BkJDy";
export var recommendationsListItem = "recommendations-module--recommendationsListItem--vepDQ";
export var recommendationsEmail = "recommendations-module--recommendationsEmail--V0YMZ";
export var recommendationsEmailTitle = "recommendations-module--recommendationsEmailTitle--L3Rnh";
export var recommendationsEmailLink = "recommendations-module--recommendationsEmailLink--0jwKc";
export var phone = "recommendations-module--phone--xwN+s";
export var recommendationsImage = "recommendations-module--recommendationsImage--Pv5FZ";
export var phoneSliderWrapper = "recommendations-module--phoneSliderWrapper--MB4HW";
export var phoneSliderInfo = "recommendations-module--phoneSliderInfo--ChUZn";
export var phoneSliderCurrent = "recommendations-module--phoneSliderCurrent--rnlFy";
export var phoneSliderCount = "recommendations-module--phoneSliderCount--VPXcZ";
export var phoneSliderProgress = "recommendations-module--phoneSliderProgress--nIYM8";
export var phoneSliderProgressValue = "recommendations-module--phoneSliderProgressValue--H6Ns9";
export var swipe = "recommendations-module--swipe--DLcS6";
export var line = "recommendations-module--line--NiZlV";
export var phoneLoader = "recommendations-module--phoneLoader--D5+G+";
export var phoneLoaderDot = "recommendations-module--phoneLoaderDot--6D8ZA";
export var dots = "recommendations-module--dots--Sxmy4";
export var phoneLoaderFrom = "recommendations-module--phoneLoaderFrom--XEpkw";
export var phoneLoaderTo = "recommendations-module--phoneLoaderTo--8wEXH";
export var phoneLoaderDot1 = "recommendations-module--phoneLoaderDot1--gEO-E";
export var phoneLoaderDot2 = "recommendations-module--phoneLoaderDot2--DdkYu";
export var phoneLoaderDot3 = "recommendations-module--phoneLoaderDot3--0rPqq";
export var phoneMessage = "recommendations-module--phoneMessage--4IdQq";
export var phoneMessageFrom = "recommendations-module--phoneMessageFrom--VJ9tz";
export var phoneSlideActive = "recommendations-module--phoneSlideActive--f1n3d";
export var messageShown = "recommendations-module--message-shown--0tS8Q";
export var phoneMessage1 = "recommendations-module--phoneMessage1--RoE0y";
export var phoneMessage2 = "recommendations-module--phoneMessage2--xqv57";
export var phoneMessage3 = "recommendations-module--phoneMessage3--7TmEn";
export var phoneMessage4 = "recommendations-module--phoneMessage4--qxVHb";
export var phoneMessage5 = "recommendations-module--phoneMessage5--1UIIo";
export var phoneLoaderTo1 = "recommendations-module--phoneLoaderTo1--+hlW-";
export var loaderTo1Opacity = "recommendations-module--loader-to-1-opacity--IsIFS";
export var loaderTo1Top = "recommendations-module--loader-to-1-top--IcJr7";
export var phoneLoaderFrom1 = "recommendations-module--phoneLoaderFrom1--AvxII";
export var loaderFrom1Top = "recommendations-module--loader-from-1-top--wIujY";
export var loaderFrom1Opacity = "recommendations-module--loader-from-1-opacity--GAr-Q";
export var phoneLoaderFrom2 = "recommendations-module--phoneLoaderFrom2--oqVJD";
export var loaderFrom2Top = "recommendations-module--loader-from-2-top--SocLk";
export var loaderFrom2Opacity = "recommendations-module--loader-from-2-opacity--RX-9u";
export var phoneLoaderTo2 = "recommendations-module--phoneLoaderTo2--KlXFz";
export var loader2 = "recommendations-module--loader-2--jS1Vt";
export var phoneLoaderFrom3 = "recommendations-module--phoneLoaderFrom3--XRb9y";
export var loader3 = "recommendations-module--loader-3--8m56M";
export var phoneLoaderTo3 = "recommendations-module--phoneLoaderTo3---WY13";