import React from 'react';
import cn from 'classnames';

import API from '../../api';

import Button from '../button/button';
import SelectedText from '../selected-text/selectedText';

import { useLinkToApp } from '../../helper/hooks';

import * as s from './helps.module.scss';

const Helps = () => {
  const { linkToRegistration } = useLinkToApp();

  const onRegistrationBtnClick = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_REGISTRATION'
      });
    } catch (e) {}
  };

  return (
    <div className={s.helps}>
      <div className="container">
        <h2 className={s.title}>СберПодбор помогает</h2>
        <div className={s.helpsContainer}>
          <div className={s.lineGradients} />
          <div className={s.stickyLine} />
          <div className={s.stickyCircle} />
          <div className={s.items}>
            <div className={s.item}>
              <h3 className={cn(s.itemTitle, 'title-3')}>Управлять<br></br> базой резюме</h3>
              <div className={s.itemContent}>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Несколько способов загрузить информацию о кандидатах в базу</h4>
                  <ul className={s.itemList}>
                    <li>
                      <SelectedText>вручную</SelectedText>&nbsp;
                      <span className={s.itemText}>— заполняйте нужные вам поля;</span>
                    </li>
                    <li>
                      <SelectedText>файлом</SelectedText>&nbsp;
                      <span className={s.itemText}>— загружайте в систему до 15 резюме за раз;</span>
                    </li>
                    <li>
                      <SelectedText>напрямую с сайтов по поиску работы</SelectedText>&nbsp;
                      <span className={s.itemText}>— используйте наше расширение для браузера;</span>
                    </li>
                    <li>
                      <SelectedText>по API</SelectedText>&nbsp;
                      <span className={s.itemText}>— открывайте контакты кандидатов с HeadHunter<br></br> прямо в СберПодборе</span>
                    </li>
                  </ul>
                  <img
                    src="/images/helps/1.png"
                    className={s.itemImage}
                    alt={'Несколько способов загрузить информацию о кандидатах в базу'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>В вашей базе не будет дублирующих резюме</h4>
                  <p className={s.itemText}>
                    СберПодбор автоматически определит и покажет дубли, если соискатель уже добавлен
                  </p>
                  <img
                    src="/images/helps/2.png"
                    className={s.itemImage}
                    alt={'В вашей базе не будет дублирующих резюме'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Находить кандидатов в базе легко</h4>
                  <p className={s.itemText}>
                    Вы найдете нужного кандидата в своей базе по имени, должности, последнему месту работы
                  </p>
                  <img
                    src="/images/helps/3.png"
                    className={s.itemImage}
                    alt={'Находить кандидатов в базе легко'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Гибкая настройка статусов воронки найма</h4>
                  <p className={s.itemText}>
                    Добавляйте и удаляйте этапы согласования для каждой вакансии отдельно
                  </p>
                  <img
                    src="/images/helps/4.png"
                    className={s.itemImage}
                    alt={'Гибкая настройка статусов воронки найма'}
                  />
                </div>
              </div>
            </div>
            <div className={s.item}>
              <h3 className={cn(s.itemTitle, 'title-3')}>Эффективно работать<br></br> с заказчиками</h3>
              <div className={s.itemContent}>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Заказчик оставляет заявку на наем сразу в СберПодбор</h4>
                  <p className={s.itemText}>Больше не придется собирать информацию из писем и мессенджеров</p>
                  <img
                    src="/images/helps/5.png"
                    className={s.itemImage}
                    alt={'Заказчик оставляет заявку на наем сразу в СберПодбор'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Согласование кандидата идет онлайн</h4>
                  <p className={s.itemText}>Все участники видят, на каком этапе кандидат и кому нужно напомнить о согласовании</p>
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Все комментарии остаются в карточке кандидата</h4>
                  <p className={s.itemText}>Они помогают участникам принять решение</p>
                  <img
                    src="/images/helps/6.png"
                    className={s.itemImage}
                    alt={'Все комментарии остаются в карточке кандидата'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Получайте обратную связь от заказчика</h4>
                  <p className={s.itemText}>Отзывы о тестовом задании, одобрение оффера или отказ</p>
                </div>
              </div>
            </div>
            <div className={s.item}>
              <h3 className={cn(s.itemTitle, 'title-3')}>Быстро и напрямую общаться<br></br> с кандидатами</h3>
              <div className={s.itemContent}>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Связаться с кандидатами можно не выходя из СберПодбора</h4>
                  <p className={s.itemText}>Пишите соискателям на почту, в Telegram или WhatsApp прямо из CRM</p>
                  <img
                    src="/images/helps/7.png"
                    className={s.itemImage}
                    alt={'Связаться с кандидатами можно не выходя из СберПодбора'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Вы получаете разрешение на обработку персональных данных без усилий</h4>
                  <p className={s.itemText}>Отправка запроса кандидату на разрешение на работу с личными данными в один клик</p>
                  <img
                    src="/images/helps/8.png"
                    className={s.itemImage}
                    alt={'Вы получаете разрешение на обработку персональных данных без усилий'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Шаблоны писем и сообщений экономят время</h4>
                  <p className={s.itemText}>Их удобно использовать, чтобы пригласить кандидата на собеседование, сообщить, что он принят, или отказать</p>
                  <img
                    src="/images/helps/9.png"
                    className={s.itemImage}
                    alt={'Шаблоны писем и сообщений экономят время'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>История общения с соискателем сохраняется навсегда</h4>
                  <p className={s.itemText}>Готовые тестовые задания и ваши комментарии останутся в карточке кандидата</p>
                </div>
              </div>
            </div>
            <div className={s.item}>
              <h3 className={cn(s.itemTitle, 'title-3')}>Контролировать<br></br> эффективность рекрутинга</h3>
              <div className={s.itemContent}>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Отчет по рекрутерам показывает, как работают сотрудники</h4>
                  <p className={s.itemText}>Вы увидите, какие сотрудники закрывают вакансии, с какими вакансиями сложности, сколько времени ушло на работу с каждой позицией</p>
                  <img
                    src="/images/helps/10.png"
                    className={s.itemImage}
                    alt={'Отчет по рекрутерам показывает, как работают сотрудники'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Отчет по отказам подсвечивает, что нужно улучшить в вакансии</h4>
                  <p className={s.itemText}>С ним удобно анализировать слабые места вакансии и кандидатов</p>
                  <img
                    src="/images/helps/11.png"
                    className={s.itemImage}
                    alt={'Отчет по отказам подсвечивает, что нужно улучшить в вакансии'}
                  />
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Отчет по источникам определяет эффективные и неэффективные площадки</h4>
                  <p className={s.itemText}>Вы сможете лучше планировать свой бюджет на поиск сотрудников</p>
                </div>
                <div className={s.itemBlock}>
                  <h4 className={s.itemSubtitle}>Воронка по вакансии демонстрирует, сколько кандидатов было на каждом этапе</h4>
                  <p className={s.itemText}>Вы увидите конверсию по каждому этапу подбора сотрудников</p>
                  <img
                    src="/images/helps/12.png"
                    className={s.itemImage}
                    alt={'Воронка по вакансии демонстрирует, сколько кандидатов было на каждом этапе'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        onClick={onRegistrationBtnClick}
        className={s.helpsButton}
        text={'Зарегистрироваться'}
        newWindow
        href={linkToRegistration}
        medium
      />
    </div>
  );
};

export default Helps;
