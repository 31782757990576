import React, { useState, useEffect  } from 'react';

import API from '../../api';

import cn from 'classnames';

import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';


import { useLinkToApp } from '../../helper/hooks';

import Button from '../button/button';

import * as s from './reasons.module.scss';

import SliderArrow from '../slider-arrow/sliderArrow';

const Reasons = () => {
  const { linkToRegistration } = useLinkToApp();

  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesCount = 3;

  const settings = {
    speed: 500,
    variableWidth: true,
    infinite: false,
    prevArrow: <SliderArrow type={'prev'} disabled={currentSlide === 0} />,
    nextArrow: <SliderArrow type={'next'} disabled={currentSlide === slidesCount} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  const [screenWidth, setScreenWidth] = useState(0);

  function onResize () {
    setScreenWidth(document.body.clientWidth);
  }

  useEffect(() => {
    setScreenWidth(document.body.clientWidth);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const onRegistrationBtnClick = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_REGISTRATION'
      });
    } catch (e) {}
  };

  return (
    <div className={s.reasons}>
      <div className={cn(s.reasonsTitle, s.reasonsTitleDuplicate)}>
        <span className={s.reasonsTitleGradient}>3 причины</span><br></br>
        использовать<br></br> СберПодбор
      </div>
      {isLoaded && <SlickSlider {...settings} className={s.reasonsSlider}>
        {screenWidth > 768 && <h2 className={cn(s.reasonsTitle, s.reasonsTitleMain)}>
          <span className={s.reasonsTitleGradient}>3 причины</span><br></br>
          использовать<br></br> СберПодбор
        </h2>}
        <div className={s.slide}>
          <h4 className={s.slideTitle}>Просто</h4>
          <div>
            <ul>
              <li className={s.listItem}>Быстрая регистрация</li>
              <li className={s.listItem}>Интуитивно понятный интерфейс</li>
              <li className={s.listItem}>Демопрезентации для новых клиентов</li>
              <li className={s.listItem}>Обучающий видеокурс</li>
              <li className={s.listItem}>Удобный перенос базы кандидатов из любой другой CRM</li>
              <li className={s.listItem}>Персональный менеджер</li>
            </ul>
          </div>
        </div>
        <div className={s.slide}>
          <h4 className={s.slideTitle}>Доступно</h4>
          <div>
            <p className={s.slideParagraph}>Бесплатный базовый функционал (бессрочно):</p>
            <ul>
              <li className={s.listItem}>доступен для  3-х пользователей с ролью администратор и рекрутер в совокупности</li>
              <li className={s.listItem}>создание воронок найма</li>
              <li className={s.listItem}>сбор разрешений на работу с персональными данными</li>
              <li className={s.listItem}>отчеты по эффективности рекрутеров и причинам отказов</li>
            </ul>
          </div>
        </div>
        <div className={s.slide}>
          <h4 className={s.slideTitle}>Безопасно<br></br> и надежно</h4>
          <div>
            <ul>
              <li className={s.listItem}>Помогает в сборе согласий на обработку персональных данных в соответствии с  ФЗ № 152-ФЗ «О персональных данных»</li>
              <li className={s.listItem}>Не зависит от иностранных компаний</li>
              <li className={s.listItem}>Сервис включен в реестр отечественного программного обеспечения</li>
            </ul>
          </div>
        </div>
      </SlickSlider>}
      <Button
        onClick={onRegistrationBtnClick}
        className={s.reasonsButton}
        href={linkToRegistration}
        text={'Зарегистрироваться '}
        newWindow
        medium
      />
    </div>
  );
};

export default Reasons;
