import React from 'react';

import API from '../../api';

import Button from '../button/button';
import SelectedText from '../selected-text/selectedText';

import { useLinkToApp } from '../../helper/hooks';

import * as s from './hero.module.scss';

const Hero = () => {
  const { linkToRegistration } = useLinkToApp();

  const onRegistrationBtnClick = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_REGISTRATION'
      });
    } catch (e) {}
  };

  const onDemoBtnClick = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_DEMO-ORDER'
      });
    } catch (e) {}
  };

  return (
    <div className={s.hero}>
      <div className="container">
        <div className={s.heroContainer}>
          <div className={s.heroLeft}>
            <h1 className={s.heroTitle}>
              Самая выгодная CRM<br></br> для рекрутеров*
            </h1>
            <p className={s.heroSubtitle}>
              Просто начните: автоматизируйте подбор, ведите единую базу кандидатов, создавайте отчеты в два клика
            </p>
            <ul className={s.heroList}>
              <li className={s.heroListItem}>
                <SelectedText>бесплатный базовый функционал</SelectedText>
              </li>
              <li className={s.heroListItem}>
                <SelectedText>входит в реестр российского ПО</SelectedText>
              </li>
            </ul>
            <div className={s.heroActions}>
              <Button
                onClick={onRegistrationBtnClick}
                className={s.heroButton}
                text={'Зарегистрироваться'}
                newWindow
                href={linkToRegistration}
                medium
              />
              <Button
                onClick={onDemoBtnClick}
                className={s.heroButton}
                text={'Заказать демо'}
                href={'#know-better'}
                black
                medium
                outline
              />
            </div>
          </div>
          <img
            className={s.heroIllustration}
            src="/images/hero.png"
            alt={'СберПодбор'}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;