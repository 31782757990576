import React, { useState } from 'react';

import cn from 'classnames';

import API from '../../api';
import { yaEvent } from '../../helper/metrika';

import Button from '../button/button';
import FAQItem from '../faq-item/faqItem';
import RequestModal from '../request-modal/requestModal';

import { useLinkToApp } from '../../helper/hooks';

import * as s from './faq.module.scss';

const FAQ = props => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    yaEvent('LANDING_CLICK_APPLICATION');
    API.metric.dispatchEvent({
      event_id: 'LANDING_CLICK_APPLICATION'
    });

    setIsOpen(true);
  };

  const {
    linkToApp
  } = useLinkToApp();

  return (
    <div className={s.faq}>
      <div className='container'>
        <h2 className={'title-2'}>Вопросы и ответы</h2>
        <div className={s.faqList}>
          <FAQItem
            title='Сколько сотрудников может работать в СберПодборе?'
            content={(
              <>
                <p>Если вы пользуетесь бесплатным базовым функционалом — в совокупности не более трех пользователей из группы рекрутеров компании с ролями «Администратор» и «Рекрутер». Вакансий и кандидатов может быть любое количество.</p>
                <p>После перехода на платный тариф ограничение на число пользователей снимается.</p>
              </>
            )}
          />
          <FAQItem
            title='Сколько стоит лицензия для одного рекрутера?'
            content={(
              <>
                <p>Стоимость тарифов «Стандартный» или «Оптимальный» едина для всех и не зависит от числа пользователей, работающих в системе. Вы можете подключить и использовать его для одного специалиста по подбору персонала, а можете — для неограниченного количества.</p>
                <p>Тарифы «Стандартный мини» или «Оптимальный мини» доступны для одного пользователя из группы рекрутеров с ролью главного администратора, их стоимость зависит от срока действия тарифа, подробнее в разделе <a href={'/#tariffs'}>Тарифы</a>.</p>
              </>
            )}
          />

          <FAQItem
            title='Видно ли рекрутеру, что делают в системе его коллеги?'
            content={(
              <>
                <p>Нет. Наблюдать за действиями всей команды может только администратор.</p>
              </>
            )}
          />
          <FAQItem
            title='Хотим подключить СберПодбор. Можно ли перенести базы кандидатов и вакансий из другой CRM-системы?'
            content={(
              <>
                <p>Да, перенос баз доступен клиентам тарифов <a href="https://sberpodbor.ru/tariffs/">«Стандартный» и «Оптимальный»</a>.</p>
              </>
            )}
          />
          <FAQItem
            title='Как выбрать подходящий тариф?'
            content={(
              <>
                <p>Вы можете ознакомиться с <a href="https://sberpodbor.ru/tariffs/">описанием тарифов</a> на сайте СберПодбора. Или оставить свои контактные данные — наши специалисты свяжутся с вами и помогут выбрать наиболее подходящий.</p>
              </>
            )}
          />
          <FAQItem
            title='Мне помогут разобраться, если у меня возникнут вопросы по работе с системой?'
            content={(
              <>
                <p>Обязательно. После подтверждения регистрации в СберПодборе с вами свяжется персональный менеджер: он ответит на все вопросы и при необходимости поможет настроить личный кабинет.</p>
                <p>А еще у нас есть инструкции и обучающее видео.</p>
              </>
            )}
          />
          <FAQItem
            title='Могут ли отклики с работных сайтов поступать в СберПодбор автоматически?'
            content={(
              <>
                <p>Да, вы можете настроить автоматический сбор откликов с Работы.ру и HeadHunter. Резюме соискателей будут приходить прямо в CRM.</p>
                Как это сделать:<br/>
                <ul>
                  <li><a href={linkToApp + '/sign-in/'} target="_blank" rel="noreferrer">авторизуйтесь или зарегистрируйтесь (это бесплатно)</a> в СберПодборе;</li>
                  <li>выберите вакансию в базе или добавьте новую;</li>
                  <li>нажмите кнопку «Собрать отклики».</li>
                </ul>
              </>
            )}
          />
        </div>
        <div className={s.faqMoreInfo}>
          <h3 className={s.faqMoreInfoTitle}>Остались вопросы?</h3>
          <p className={s.faqMoreInfoText}>Оставьте заявку на консультацию, мы свяжемся с вами и на все ответим!</p>
          <Button
            onClick={openModal}
            text={'Оставить заявку'}
            noTitle
            large
          />
        </div>
      </div>
      <RequestModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className='tariffs-modal'
        formName={'faq'}
      />
    </div>
  );
};

export default FAQ;
