// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--6D8n+";
export var heroContainer = "hero-module--heroContainer--Z7UVf";
export var heroIcon = "hero-module--heroIcon--85tDo";
export var heroLeft = "hero-module--heroLeft--t1AvN";
export var heroTitle = "hero-module--heroTitle--TRIvQ";
export var heroSubtitle = "hero-module--heroSubtitle--wi0ZG";
export var heroIllustration = "hero-module--heroIllustration--eH6Db";
export var heroList = "hero-module--heroList--QzCer";
export var heroListItem = "hero-module--heroListItem--uIIsr";
export var heroActions = "hero-module--heroActions--uNi86";
export var heroButton = "hero-module--heroButton--VGipy";