// extracted by mini-css-extract-plugin
export var slider = "slider-module--slider--UBNwU";
export var sliderTitle = "slider-module--sliderTitle--1BCs9";
export var slickSlider = "slider-module--slickSlider--+lTwf";
export var slide = "slider-module--slide--Ro1ZG";
export var slickSlide = "slider-module--slickSlide--WsJWv";
export var slideHeader = "slider-module--slideHeader--CDnIi";
export var slideImg = "slider-module--slideImg--7hta8";
export var slideUserName = "slider-module--slideUserName----Js+";
export var slideCompany = "slider-module--slideCompany--NOKMV";
export var slidePosition = "slider-module--slidePosition--AwyAA";
export var slideContent = "slider-module--slideContent--pjLPo";