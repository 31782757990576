import React from 'react';
import cn from 'classnames';

import ArrowIcon from '../../assets/icons/slider-arrow.svg';

import * as s from './sliderArrow.module.scss';

const SliderArrow = props => {
  const {
    type,
    disabled,
    onClick,
    className = []
  } = props;

  const classes = cn(
    s.arrow,
    type === 'next' ? s.arrowNext : s.arrowPrev,
    {
      [s.arrowDisabled]: disabled
    },
    className
  );

  return (
    <div
      className={classes}
      onClick={onClick}
    >
      <ArrowIcon className={s.arrowIcon} />
    </div>
  );
};

export default SliderArrow;
