import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cn from 'classnames';

import * as s from './faqItem.module.scss';

const FAQItem = props => {
  const { title, content } = props;

  const [height, setHeight] = useState(0);

  const toggle = () => {
    setHeight(height === 0 ? 'auto' : 0);
  };

  return (
    <div className={cn(s.faqItem, { [s.faqItemExpanded]: Boolean(height) })}>
      <div className={s.faqItemHeader} onClick={toggle}>
        <h4>
          {title}
        </h4>
        <div className={s.faqItemIcon}></div>
      </div>
      <AnimateHeight
        height={height}
        aria-hidden={Boolean(height)}
        duration={250}
      >
        <div
          className={cn(s.faqItemContent)}>
          {content}
        </div>
      </AnimateHeight>
    </div>
    );
};

export default FAQItem;
