// extracted by mini-css-extract-plugin
export var reasons = "reasons-module--reasons--mdpzb";
export var reasonsSlider = "reasons-module--reasonsSlider--JX0Td";
export var reasonsTitle = "reasons-module--reasonsTitle--14EWL";
export var reasonsTitleDuplicate = "reasons-module--reasonsTitleDuplicate--Bnu5D";
export var reasonsTitleMain = "reasons-module--reasonsTitleMain--e3PtV";
export var reasonsTitleGradient = "reasons-module--reasonsTitleGradient--BZgiZ";
export var slider = "reasons-module--slider--c-coN";
export var slide = "reasons-module--slide--7kROk";
export var slideTitle = "reasons-module--slideTitle--GmIGj";
export var listItem = "reasons-module--listItem--thpkp";
export var slideParagraph = "reasons-module--slideParagraph--XX14i";
export var reasonsButton = "reasons-module--reasonsButton--iZxHm";