import React from 'react';
import cn from 'classnames';

import API from '../../api';

import { yaEvent } from '../../helper/metrika';

import Form from '../form/form';
import Button from '../button/button';

import * as s from './knowBetter.module.scss';

const KnowBetter = () => {
  const onDownloadBtnClick = () => {
    try {
      yaEvent('pushed_download_presentation_button');
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_DOWNLOAD'
      });
    } catch (e) {}
  };

  return (
    <div className={s.knowBetter}>
      <div className="container">
        <h2 className="title-2">Хотите лучше узнать, как работает СберПодбор?</h2>
        <div className={s.knowBetterContainer}>
          <div className={s.presentation}>
            <h4 className={cn(s.presentationTitle, 'title-3')}>
              Посмотрите презентацию обо всех возможностях сервиса
            </h4>
            <Button
              onClick={onDownloadBtnClick}
              text={'Скачать'}
              href={'/doc/СберПодбор_презентация_про_продукт.pdf'}
              newWindow
              black
              outline
              medium
            />
          </div>
          <div className={s.request}>
            <h3 className={cn(s.requestTitle, 'title-3')}>
              Оставьте свои контакты
            </h3>
            <p className={s.requestText}>
              Мы проведем для вас онлайн-встречу в удобное время, покажем все возможности системы и ответим на вопросы.
            </p>
            <div>
              <Form formName={'presentation'} />
            </div>
          </div>
        </div>
      </div>
  </div>
  );
};

export default KnowBetter;